import * as React from 'react'

/** Sections */
import LandingSection from '../components/landing-section'
import CountdownSection from '../components/countdown-section'
import Promotions from '../components/promotions'
import Partners from '../components/partners'
import FAQSection from '../components/faq-section'
import Footer from '../components/footer'
import SEO from '../components/miscellaneous/seo'

/** Styles */
import './index.css'

const IndexPage: React.FC = () => (
  <main className="app">
    <SEO />
    <LandingSection />
    <Promotions />
    <CountdownSection />
    <Partners />
    <FAQSection />
    <Footer />
  </main>
)

export default IndexPage
