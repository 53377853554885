import * as React from 'react'

/** Styles */
import './partners.css'

/** Assets */
import StuCred from '../../assets/vectors/stucred.svg'
import CB from '../../assets/vectors/cb.svg'

const Partners: React.FC = () => {
  return (
    <section className="part">
      <h1 className="part__heading" id="part-heading">
        Empowerment Partners
      </h1>
      <main className="part__logos">
        <a
          href="https://play.google.com/store/apps/details?id=com.kreon.stucred"
          target="_blank"
          rel="noreferrer noopener"
        >
          <StuCred />
        </a>
        <a
          href="https://codingblocks.com/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <CB />
        </a>
      </main>
    </section>
  )
}

export default Partners
