import * as React from 'react'
import { gsap } from 'gsap'

/** Styles */
import './landing-section.css'

/** Assets */
import Environment from '../../assets/vectors/environment.svg'
import TopCircle from '../../assets/vectors/top-circle.svg'
import BottomCircle from '../../assets/vectors/bottom-circle.svg'
import Logo from '../../assets/vectors/logo.svg'

const LandingSection: React.FC = () => {
  React.useEffect(() => {
    const tl: gsap.core.Timeline = gsap.timeline()
    tl.fromTo(
      '#heading',
      { x: -50, opacity: 0 },
      { x: 0, opacity: 1, duration: 0.5, delay: 0.8 }
    )
      .fromTo(
        '#info',
        { x: -50, opacity: 0 },
        { x: 0, opacity: 1, duration: 0.5 },
        '-=0.3'
      )
      .fromTo(
        '#date',
        { x: -50, opacity: 0 },
        { x: 0, opacity: 1, duration: 0.5 },
        '-=0.3'
      )
      .fromTo(
        '#btn-group',
        { x: -50, opacity: 0 },
        { x: 0, opacity: 1, duration: 0.5 },
        '-=0.3'
      )
      .fromTo(
        '#il',
        { y: 50, opacity: 0 },
        { y: 0, opacity: 1, duration: 0.5 },
        '-=0.3'
      )
    gsap
      .to('#top-circle', { rotation: '360', repeat: -1, ease: 'none' })
      .timeScale(0.2)
    gsap
      .to('#bottom-circle', { rotation: '360', repeat: -1, ease: 'none' })
      .timeScale(0.2)
  }, [])

  return (
    <section className="landing">
      <TopCircle />
      <BottomCircle />
      <article className="landing__banner" id="left">
        <Logo />
        <h3 className="landing__banner__info" id="info">
          VCC (VIT Coding Challenge) is a coding competition with an aim to
          improve the coding culture in VIT. It will be a series of competitive
          contests that will test and improve the problem-solving skills of the
          participants.
        </h3>
        <h3 className="landing__banner__date" id="date">
          Season 2: April 7th, 8:00 PM
        </h3>
        <div className="landing__banner__btn-group" id="btn-group">
          <button
            type="button"
            className="landing__banner__btn-group__btn"
            onClick={() =>
              window.location.replace('https://go.vitspot.com/vcc-s2')
            }
          >
            Register
          </button>
          <button
            type="button"
            className="landing__banner__btn-group__btn landing__banner__btn-group__btn--right hidden"
          >
            Button B
          </button>
        </div>
      </article>
      <Environment />
    </section>
  )
}

export default LandingSection
