import * as React from 'react'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

/** Styles */
import './faq-section.css'

/** Assets */
import Arrow from '../../assets/vectors/arrow.svg'

const FAQSection = () => {
  const [open, setOpen] = React.useState<number>(-1)

  const openAccordion = (index: number): void => {
    setOpen(-1)
    if (index !== open) setOpen(index)
  }

  const DATA = [
    {
      question: 'What is VCC?',
      answer:
        'VCC (VIT Coding Challenge) is a coding competition with an aim to improve the coding culture in all the four campuses of VIT. It will be a series of competitive contests that will test and improve the problem-solving skills of the participants. We aim to make this a practice session for placements and a self-assessment for each participant to know where he/she stands along with others in their batch. The top performers will be displayed on all the VITspot social handles and their names will be added to the VCC Hall of fame.',
    },
    {
      question: 'Who is hosting this contest?',
      answer: 'This contest is hosted by VITspot.',
    },
    {
      question: "I'm a Fresher. Can I participate?",
      answer:
        "Yes, of course! This will be a kickstart to your coding journey and you can get feedback on where you stand in your batch. The best part in VCC is, all of your solutions to a problem will be given equal recognition as we'll be rewarding each category, say, Freshers, Second years, third and final years.",
    },
    {
      question: 'Why should I take part in VCC?',
      answer:
        "This is the largest platform where you can practice along with like-minded people from your college with whom you'll sit for placements. \nThe event will be a great learning experience for those who are learning to code and trying to work on their logical thinking abilities.We'll be sharing solutions too. To make this a fair event, we'll be recognizing and choosing winners from each year of study. \nThis will give you a glimpse of where you stand every month.",
    },
    {
      question: 'How much should I pay to participate?',
      answer: "No participation fee. It's completely FREE!",
    },
    {
      question:
        'Whom should I contact if I have any issues during the contest?',
      answer:
        'Please reach out to Ansh (+91 99201 72209) and Ayush (+91 88262 95146).',
    },
  ]

  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.from('#faq-heading', {
      scrollTrigger: '#faq-heading',
      x: -50,
      opacity: 0,
    })
    gsap.from('#faq-info', {
      scrollTrigger: '#faq-info',
      x: -50,
      opacity: 0,
    })
    gsap.from('#faq-accordion', {
      scrollTrigger: '#faq-accordion',
      y: 100,
      opacity: 0,
      delay: 2.5,
    })
  }, [])

  return (
    <section className="faq">
      <header>
        <h1 className="faq__heading" id="faq-heading">
          Have Questions?
        </h1>
        <h3 className="faq__info" id="faq-info">
          Here are some FAQs
        </h3>
      </header>
      <main>
        {DATA.map((item, i) => (
          <article className="faq__accordion" key={i} id="faq-accordion">
            <button
              type="button"
              className={`faq__accordion__touch ${
                open === i && 'faq__accordion__touch--active'
              }`}
              onClick={() => openAccordion(i)}
            >
              <span>{item.question}</span>
              <span
                className={`faq__accordion__touch__il ${
                  open === i && 'faq__accordion__touch__il--active'
                }`}
              >
                <Arrow />
              </span>
            </button>
            <div
              className={`faq__accordion__info ${
                open === i && 'faq__accordion__info--active'
              }`}
            >
              <span
                className={`faq__accordion__info__content ${
                  open === i && 'faq__accordion__info__content--active'
                }`}
              >
                {item.answer}
              </span>
            </div>
          </article>
        ))}
      </main>
    </section>
  )
}
export default FAQSection
