import * as React from 'react'

/** Styles */
import './footer.css'

const Footer = () => (
  <footer className="footer">
    <span>
      An initiative by&nbsp;
      <a
        href="https://vitspot.com"
        target="_blank"
        rel="noopener noreferrer"
        className="footer__link"
      >
        VITspot
      </a>
      {/* &nbsp;and&nbsp;
      <a
        href="https://acmvit.in"
        target="_blank"
        rel="noopener noreferrer"
        className="footer__link"
      >
        ACM VIT
      </a> */}
    </span>
    <span>
      Developed by&nbsp;
      <a
        href="https://github.com/helixw"
        target="_blank"
        rel="noopener noreferrer"
        className="footer__link"
      >
        Shreyas K.
      </a>
    </span>
  </footer>
)

export default Footer
