import * as React from 'react'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { interval } from 'rxjs'
import { map } from 'rxjs/operators'

/** Styles */
import './countdown-section.css'

const CountdownSection: React.FC = () => {
  const [days, setDays] = React.useState<number>(0)
  const [hours, setHours] = React.useState<number>(0)
  const [minutes, setMinutes] = React.useState<number>(0)
  const [seconds, setSeconds] = React.useState<number>(0)

  const eventEndsAt = 'Wed Apr 7 2021 20:00:00 GMT+0530 (India Standard Time)'
  let diff: number

  const getDays = (t: number): number => Math.floor(t / (1000 * 60 * 60 * 24))

  const getHours = (t: number): number =>
    Math.floor((t / (1000 * 60 * 60)) % 24)

  const getMinutes = (t: number): number => Math.floor((t / 1000 / 60) % 60)

  const getSeconds = (t: number): number => Math.floor((t / 1000) % 60)

  const startTimer = (): void => {
    interval(1000)
      .pipe(
        map(_ => {
          diff = Date.parse(eventEndsAt) - Date.parse(new Date().toString())
        })
      )
      .subscribe(_ => {
        setDays(getDays(diff))
        setHours(getHours(diff))
        setMinutes(getMinutes(diff))
        setSeconds(getSeconds(diff))

        // Event expiry
        if (diff < 0) {
          setDays(0)
          setHours(0)
          setMinutes(0)
          setSeconds(0)
        }
      })
  }

  React.useEffect(() => {
    startTimer()

    gsap.registerPlugin(ScrollTrigger)
    gsap.from('#count-heading', {
      scrollTrigger: '#count-heading',
      x: -50,
      opacity: 0,
    })
    gsap.from('#count-timer', {
      scrollTrigger: '#count-timer',
      y: 100,
      opacity: 0,
      delay: 2.5,
    })
  }, [])

  return (
    <section className="count" id="count">
      <h1 className="count__heading" id="count-heading">
        Season 2 Starts In
      </h1>
      <article className="count__timer" id="count-timer">
        <div>
          <div className="count__timer__digits">
            {days.toString().length === 1 ? `0${days}` : days}
          </div>
          <div className="count__timer__text">Days</div>
        </div>
        <span className="count__timer__sep">:</span>
        <div>
          <div className="count__timer__digits">
            {hours.toString().length === 1 ? `0${hours}` : hours}
          </div>
          <div className="count__timer__text">Hours</div>
        </div>
        <span className="count__timer__sep">:</span>
        <div>
          <div className="count__timer__digits">
            {minutes.toString().length === 1 ? `0${minutes}` : minutes}
          </div>
          <div className="count__timer__text">Mins</div>
        </div>
        <span className="count__timer__sep">:</span>
        <div>
          <div className="count__timer__digits">
            {seconds.toString().length === 1 ? `0${seconds}` : seconds}
          </div>
          <div className="count__timer__text">Secs</div>
        </div>
      </article>
    </section>
  )
}

export default CountdownSection
