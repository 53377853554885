import * as React from 'react'

/** Styles */
import './promotions.css'

/** Assets */
import Banner from '../../assets/vectors/banner.svg'

const Promotions = () => {
  return (
    <section className="prom">
      <a href="https://c2c.acmvit.in" className="prom__link">
        <Banner />
      </a>
    </section>
  )
}

export default Promotions
